import React, { useState } from "react";
import { motion } from "framer-motion";
import { Typography, Box, Container, Modal } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import oneI from "./caro1.jpg";
import twoI from "./caro2.jpg";
import ThreeI from "./caro3.jpg";
import FourI from "./caro4.jpg";
import video from "../../Images/Video.MOV"; // Ensure this video is correctly imported
import CloseIcon from "@mui/icons-material/Close";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const galleryItems = [
  {
    type: "image",
    src: oneI,
    caption: "",
  },
  {
    type: "video",
    src: video,
    caption: "",
  },
  {
    type: "image",
    src: twoI,
    caption: "",
  },
  {
    type: "image",
    src: ThreeI,
    caption: "",
  },
  {
    type: "video",
    src: video,
    caption: "",
  },
  {
    type: "image",
    src: FourI,
    caption: "",
  },
];

const GalleryItem = ({ item, onClick }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className="cursor-pointer overflow-hidden rounded-lg shadow-lg"
    onClick={() => onClick(item)}
  >
    {item.type === "image" ? (
      <img
        src={item.src}
        alt={item.caption}
        className="w-full h-64 object-cover"
      />
    ) : (
      <div className="relative w-full h-64 bg-gray-200 flex items-center justify-center">
        <img
          src={oneI}
          alt={item.caption}
          className="w-full h-full object-cover "
        />
        <PlayArrowIcon className="absolute text-white text-6xl" />
      </div>
    )}
    <Typography variant="caption" className="p-2 text-center block bg-white">
      {item.caption}
    </Typography>
  </motion.div>
);

const GalleryModal = ({ item, onClose }) => {
  // Check if item is null before trying to access its properties
  if (!item) return null; // If item is null, do not render anything

  return (
    <Modal
      open={Boolean(item)}
      onClose={onClose}
      className="flex items-center justify-center"
    >
      <Box className="bg-white rounded-lg p-4 max-w-3xl w-full mx-4">
        <Box className="flex justify-end mb-2">
          <CloseIcon onClick={onClose} className="cursor-pointer" />
        </Box>
        {item.type === "image" ? (
          <img
            src={item.src}
            alt={item.caption}
            className="w-full max-h-[70vh] object-contain"
          />
        ) : (
          <video
            src={item.src}
            autoPlay
            controls
            className="w-full max-h-[70vh]"
          >
            Your browser does not support the video tag.
          </video>
        )}
        <Typography variant="body1" className="mt-4 text-center">
          {item.caption}
        </Typography>
      </Box>
    </Modal>
  );
};

const Gallery = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <div className="py-5">
      <motion.h1
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-4xl md:text-5xl font-bold text-center  text-purple-800"
      >
        Our Impact Gallery
      </motion.h1>

      <Typography variant="h6" className="text-center mb-8 text-gray-600">
        Witness the journey of empowerment through education and guidance across
        India
      </Typography>

      <Carousel
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
        className="mb-12"
      >
        {galleryItems.map((item, index) => (
          <div key={index} className="h-96 m-6">
            <img
              src={item.type === "image" ? item.src : oneI} // Updated to use image for video
              alt={item.caption}
              className="w-full h-full object-cover "
            />
            <p className="legend">{item.caption}</p>
          </div>
        ))}
      </Carousel>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 m-6">
        {galleryItems.map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <GalleryItem item={item} onClick={setSelectedItem} />
          </motion.div>
        ))}
      </div>

      <GalleryModal item={selectedItem} onClose={() => setSelectedItem(null)} />
    </div>
  );
};

export default Gallery;
