// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import axios from "axios";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Button,
// } from "@mui/material";

// const ShowCourse = () => {
//   const { courseId } = useParams();
//   const [course, setCourse] = useState(null);
//   const [currentVideo, setCurrentVideo] = useState("");

//   useEffect(() => {
//     const fetchCourse = async () => {
//       try {
//         const response = await axios.get(
//           `http://localhost:4000/api/course/courses/${courseId}`
//         );
//         setCourse(response.data);
//         setCurrentVideo(response.data.playlist_links[0]);
//       } catch (error) {
//         console.error("Error fetching course:", error);
//       }
//     };
//     fetchCourse();
//   }, [courseId]);

//   const handleVideoClick = (videoLink) => {
//     setCurrentVideo(videoLink);
//     console.log(currentVideo);
//   };

//   return (
//     <div className="flex flex-row justify-center">
//       <div className="w-1/2 p-4">
//         <iframe
//           width="100%"
//           height="400"
//           src={`https://www.youtube.com/embed/${currentVideo}`}
//           allowFullScreen
//           title="Project Artha"
//           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//         ></iframe>
//       </div>
//       <div className="w-1/2 p-4">
//         <h2 className="text-xl font-bold mb-4">Course Playlist</h2>
//         <TableContainer component={Paper}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>Video</TableCell>
//                 <TableCell>Action</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {course &&
//                 course.playlist_links.map((videoLink, index) => (
//                   <TableRow key={index}>
//                     <TableCell>Lecture {index + 1}</TableCell>
//                     <TableCell>
//                       <Button
//                         className="cursor-pointer"
//                         onClick={() => handleVideoClick(videoLink)}
//                       >
//                         Play
//                       </Button>
//                     </TableCell>
//                   </TableRow>
//                 ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </div>
//     </div>
//   );
// };

// export default ShowCourse;
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
} from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1F0954",
      // Your primary color
    },
    typography: {
      fontFamily: "Poppins, sans-serif",
    },
  },
});

const ShowCourse = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [currentVideo, setCurrentVideo] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await axios.get(
          `http://localhost:4000/api/course/courses/${courseId}`
        );
        setCourse(response.data);
        setCurrentVideo(response.data.playlist_links[0]);
      } catch (error) {
        console.error("Error fetching course:", error);
      }
    };
    fetchCourse();
  }, [courseId]);

  const handleVideoClick = (videoLink) => {
    setCurrentVideo(videoLink);
    console.log(currentVideo);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="flex flex-row justify-center">
        <div className="w-1/2 p-4">
          <iframe
            width="100%"
            height="400"
            src={`https://www.youtube.com/embed/${currentVideo}`}
            allowFullScreen
            title="Project Artha"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
        <div className="w-1/2 p-4">
          <h2 className="text-xl font-poppins font-bold mb-4">
            Course Playlist
          </h2>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                    Video
                  </TableCell>
                  <TableCell sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {course &&
                  course.playlist_links
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((videoLink, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ fontFamily: "Poppins" }}>
                          Lecture {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell>
                          <Button
                            sx={{ fontFamily: "Poppins" }}
                            className="cursor-pointer"
                            onClick={() => handleVideoClick(videoLink)}
                          >
                            Play
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={course ? course.playlist_links.length : 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ShowCourse;
