// import React from "react";
// import { Grid, Typography, Button } from "@mui/material";
// import Carousel from "react-material-ui-carousel";
// import { Link } from "react-router-dom";
// import { fontFamily } from "@mui/system";

// function Home() {
//   return (
//     <div style={{ fontFamily: "Poppins" }}>
//       <div className="lg:min-h-screen hero-section text-center lg:pt-20">
//         <Grid
//           container
//           alignItems="center"
//           className="text-center container justify-center pt-5"
//         >
//           <Grid
//             item
//             xs={12}
//             sx={{ fontFamily: "Poppins" }}
//             md={8}
//             className="lg:pt-20"
//           >
//             <Typography
//               color="#226575"
//               variant="h3"
//               className="lg:text-4xl"
//               sx={{ fontWeight: "bold", mb: 5, fontFamily: "Poppins" }}
//             >
//               Welcome to Project Artha
//             </Typography>

//             <h3 className="font-bold text-2xl lg:my-5 md:text-3xl">
//               Empowering all with financial literacy for economic prosperity.
//             </h3>
//             <h4 className="font-bold lg:my-5 md:mx-5 text-2xl md:text-3xl">
//               Join us in breaking barriers and building equality.
//             </h4>
//           </Grid>
//           <Grid item md={4} xs={10}>
//             <img src="/images/Logo.png" alt="Logo" />
//           </Grid>
//         </Grid>
//       </div>

//       <div className="lg:min-h-50% text-center  pt-5 bg-[#]">
//         <Grid
//           sx={{ fontFamily: "Poppins" }}
//           container
//           className="container justify-center"
//         >
//           <Grid
//             md={10}
//             xs={12}
//             className="card  rounded-lg mt-2"
//             sx={{ backgroundColor: "#D1F9FF", p: 2, fontFamily: "Poppins" }}
//           >
//             <div className="">
//               <Typography
//                 variant="h5"
//                 color="#1F0954"
//                 sx={{ fontWeight: "bold", mb: 2, fontFamily: "Poppins" }}
//               >
//                 VISION
//               </Typography>
//               <Typography
//                 sx={{ fontFamily: "Poppins" }}
//                 className="px-"
//                 variant="body1"
//               >
//                 Project Artha aims to empower young women by equipping them with
//                 the necessary skills to practice artha throughout their lives.
//               </Typography>
//               <Typography
//                 color="#1F0954"
//                 variant="h5"
//                 sx={{ fontWeight: "bold", my: 2, fontFamily: "Poppins" }}
//               >
//                 MISSION
//               </Typography>
//               <Typography
//                 sx={{ fontFamily: "Poppins" }}
//                 className="px-5 pb-8"
//                 variant="body1"
//               >
//                 Rooted in the profound principles of artha, we are committed to
//                 transforming the lives of youth by equipping them with financial
//                 literacy. Our volunteers embark on mission trips to schools in
//                 underprivileged regions around the world, where they teach a
//                 comprehensive curriculum on financial literacy. Our commitment
//                 doesn't end with these trips; we maintain an ongoing
//                 relationship with our students by providing mentorship, and
//                 supporting them as they navigate adulthood. Project Artha
//                 strives to foster an environment where all youth can sustain
//                 their financial means.
//               </Typography>
//             </div>
//           </Grid>
//           {/* <Grid
//             md={5}
//             xs={12}
//             className="md:ml-5 vm-card rounded-lg mt-2"
//             sx={{ backgroundColor: "#fff", p: 2 }}
//           >
//             <Typography
//               color="#1F0954"
//               variant="h5"
//               sx={{ fontWeight: "bold", mb: 2 }}
//             >
//               MISSION
//             </Typography>
//             <Typography variant="body1">
//               Rooted in the profound principles of artha, we are committed to
//               transforming the lives of youth by equipping them with financial
//               literacy. Our volunteers embark on mission trips to schools in
//               underprivileged regions around the world, where they teach a
//               comprehensive curriculum on financial literacy. Our commitment
//               doesn't end with these trips; we maintain an ongoing relationship
//               with our students by providing mentorship, and supporting them as
//               they navigate adulthood. Project Artha strives to foster an
//               environment where all youth can sustain their financial means.
//             </Typography>
//           </Grid> */}

//           <div className="container text-center mb-20 mt-8">
//             {/* <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3 }}>
//               Help Project Artha make a difference
//             </Typography> */}
//             <Typography
//               variant="body1"
//               className="md:px-10 "
//               sx={{ mb: 3, fontFamily: "Poppins" }}
//             >
//               Our organization is committed to teaching young women about
//               finances. With your support, we can make a positive impact on the
//               world. Join us today!
//             </Typography>
//             <div
//               style={{ display: "flex", justifyContent: "center" }}
//               class=" text-center mt-10 wrapper"
//             >
//               <a className="ab">
//                 <span>Find Out More!</span>
//               </a>
//             </div>
//           </div>
//         </Grid>
//       </div>

//       <div className=" container my-20 text-center ">
//         <Typography
//           variant="body"
//           className="text-xl lg:text-3xl "
//           sx={{ fontWeight: "bold", py: 10, fontFamily: "Poppins" }}
//         >
//           Become part of Project Artha
//         </Typography>
//         <Grid container className="justify-center">
//           {/* <div style={{ display: "flex", justifyContent: "center" }}>
//               <Link
//                 to="/mentorlogin"
//                 style={{ textDecoration: "none", width: "200px" }}
//               >
//                 <div class=" mt-10 wrapper">
//                   <a className="ab">
//                     <span>Login as Mentor</span>
//                   </a>
//                 </div>
//               </Link>
//             </div> */}
//           {/* <div style={{ display: "flex", justifyContent: "center" }}>
//               <Link
//                 to="/mentorlogin"
//                 style={{ textDecoration: "none", width: "200px" }}
//               >
//                 <div class=" mt-10 mb-4 wrapper">
//                   <a className="ab">
//                     <span>SignUp as Mentor</span>
//                   </a>
//                 </div>
//               </Link>
//             </div> */}

//           {/* <div className="ml-[9rem] w-[16rem] become_part">
//               <img src="/images/become_part.png" alt="" />
//             </div> */}

//           <Grid className="mt-10" item xs={12} md={8}>
//             <Carousel autoPlay={true} animation="slide">
//               <img src="/images/caro1.jpg" alt="" />
//               <img src="/images/caro2.jpg" alt="" />
//               <img src="/images/caro3.jpg" alt="" />
//               <img src="/images/caro4.jpg" alt="" />

//               {/* Add more carousel items as needed */}
//             </Carousel>
//           </Grid>
//         </Grid>
//       </div>
//     </div>
//   );
// }

// export default Home;

import React from "react";
import { Grid, Typography, Button, Container } from "@mui/material";

import Carousel from "react-material-ui-carousel";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Home() {
  const back = "url('./Home.jpg')";
  return (
    <div
      style={{
        fontFamily: "Poppins",
        backgroundImage: back,
        backgroundSize: "cover", // Cover the entire container
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent the image from repeating
      }}
    >
      {/* Hero Section */}
      <Container maxWidth="lg" style={{ background: { back } }}>
        <div className="responsive-container">
          <Grid alignItems="center" spacing={4}>
            {/* <Grid item xs={12} md={8}>
              {/* <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Typography
                  color="#226575"
                  variant="h3"
                  className="text-3xl lg:text-5xl"
                  sx={{ fontWeight: "bold", mb: 3 }}
                >
                  Welcome to Project Artha
                </Typography>
              </motion.div> */}

            {/* <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <Typography variant="h5" className="mb-4">
                  Empowering all with financial literacy for economic
                  prosperity.
                </Typography>
                <Typography variant="h5" className="mb-6">
                  Join us in breaking barriers and building equality.
                </Typography>
              </motion.div> */}
            {/* </Grid> */}

            {/* <Grid item xs={12} md={4}> */}
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8 }}
              style={{
                // width: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <img
                src="/images/NewLogo.png"
                alt="Logo"
                className="w-full max-w-xs mx-auto"
              />
            </motion.div>
            {/* </Grid> */}
          </Grid>

          {/* Vision and Mission Section */}
          <Grid container spacing={4} className="mt-12">
            {/* Vision */}
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <div
                  className="p-6 rounded-lg shadow-md bg-[#FAF8F5] border-l-4 border-[#226575]"
                  style={{
                    minHeight: "250px", // Set a minimum height here
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="#226575"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Vision
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1.1rem",
                      lineHeight: 1.6,
                      color: "#444444",
                    }}
                  >
                    Project Artha aims to empower young women by equipping them
                    with the necessary skills to practice artha throughout their
                    lives.
                  </Typography>
                </div>
              </motion.div>
            </Grid>

            {/* Mission */}
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <div
                  className="p-6 rounded-lg shadow-md bg-[#FAF8F5] border-l-4 border-[#226575]"
                  style={{
                    minHeight: "250px", // Match the height here as well
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="#226575"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Mission
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1.1rem",
                      lineHeight: 1.6,
                      color: "#444444",
                    }}
                  >
                    Rooted in the profound principles of artha, we are committed
                    to transforming the lives of youth by equipping them with
                    financial literacy. Our volunteers embark on mission trips
                    to schools in underprivileged regions around the world,
                    teaching financial literacy.
                  </Typography>
                </div>
              </motion.div>
            </Grid>
          </Grid>
        </div>
      </Container>

      {/* Carousel and Buttons */}
      <div className="bg-gray-100 py-16">
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Carousel */}
            <Grid item xs={12} md={8}>
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                <Carousel autoPlay={true} animation="slide">
                  <img
                    src="/images/caro1.jpg"
                    alt=""
                    className="w-full h-auto rounded-lg shadow-md"
                  />
                  <img
                    src="/images/caro2.jpg"
                    alt=""
                    className="w-full h-auto rounded-lg shadow-md"
                  />
                  <img
                    src="/images/caro3.jpg"
                    alt=""
                    className="w-full h-auto rounded-lg shadow-md"
                  />
                  <img
                    src="/images/caro4.jpg"
                    alt=""
                    className="w-full h-auto rounded-lg shadow-md"
                  />
                </Carousel>
              </motion.div>
            </Grid>
            {/* Buttons */}
            <Grid item xs={12} md={4}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="flex flex-col space-y-4"
              >
                <Typography variant="h4" className="text-center font-bold mb-8">
                  Become part of Project Artha
                </Typography>
                <Link to="/login" style={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      fontFamily: "Poppins",
                      backgroundColor: "#226575",
                      "&:hover": { backgroundColor: "#184D4D" },
                      py: 1.5,
                    }}
                  >
                    LOGIN AS MENTOR
                  </Button>
                </Link>

                <Link to="/login" style={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      fontFamily: "Poppins",
                      backgroundColor: "#226575",
                      "&:hover": { backgroundColor: "#184D4D" },
                      py: 1.5,
                    }}
                  >
                    LOGIN AS STUDENT
                  </Button>
                </Link>

                <Link to="/donate" style={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      fontFamily: "Poppins",
                      backgroundColor: "#226575",
                      "&:hover": { backgroundColor: "#184D4D" },
                      py: 1.5,
                    }}
                  >
                    DONATE
                  </Button>
                </Link>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default Home;
