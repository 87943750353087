import React from "react";
import { Typography, Paper, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";

const MentorDashboard = () => {
  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Mentor Dashboard
      </Typography>
      <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          Welcome, Mentor!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Here, you can access various mentor-related functionalities and
          features.
        </Typography>
      </Paper>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Typography variant="h6" gutterBottom>
              View Your Students
            </Typography>
            <Typography variant="body1" gutterBottom>
              View and manage the students assigned to you.
            </Typography>
            <Button
              variant="contained"
              component={Link}
              to="/mentor/students"
              color="primary"
            >
              View Students
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Typography variant="h6" gutterBottom>
              Create New Course
            </Typography>
            <Typography variant="body1" gutterBottom>
              Create a new course to teach your students.
            </Typography>
            <Button
              variant="contained"
              component={Link}
              to="/mentor/create-course"
              color="primary"
            >
              Create Course
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default MentorDashboard;
