import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ThemeProvider } from "@mui/system";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1F0954",
    },
    typography: {
      fontFamily: "Poppins, sans-serif",
    },
  },
});

const KhanAcademy = () => {
  const [videos, setVideos] = useState([]);
  const [currentVideo, setCurrentVideo] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          "http://localhost:4000/api/course/videos"
        );
        setVideos(response.data);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };
    fetchVideos();
  }, []);

  const handlePlayClick = (videoId) => {
    setCurrentVideo(videoId);
    setShowVideo(true);
  };

  const handleCloseClick = () => {
    setShowVideo(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ fontFamily: "Poppins" }} className="container mx-auto px-4">
        {showVideo ? (
          <div className="w-full h-[300px] lg:h-[500px] relative">
            <iframe
              className="w-full h-full"
              src={`https://www.youtube.com/embed/${currentVideo}`}
              allowFullScreen
              title="Khan Academy Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
            <button
              className="absolute top-4 right-4 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
              onClick={handleCloseClick}
            >
              Back
            </button>
          </div>
        ) : (
          <div>
            <h2 className="text-2xl font-bold mb-4">All Videos</h2>
            <TableContainer
              sx={{ fontFamily: "Poppins" }}
              component={Paper}
              className="mb-8"
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
                    >
                      Title
                    </TableCell>
                    <TableCell
                      sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {videos
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((video) => (
                      <TableRow
                        key={video.video_id}
                        className="hover:bg-gray-100"
                      >
                        <TableCell sx={{ fontFamily: "Poppins" }}>
                          {video.title}
                        </TableCell>
                        <TableCell sx={{ fontFamily: "Poppins" }}>
                          {video.description}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handlePlayClick(video.video_link)}
                            className="bg-blue-500 hover:bg-blue-700"
                          >
                            Watch Video
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={videos.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              className="flex justify-end"
            />
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default KhanAcademy;
