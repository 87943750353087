import "./impact.css";
import { Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Impact() {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{ fontFamily: "Poppins" }}
        className="lg:min-h-screen impact-bg text-white text-center py-10"
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <h2 className="text-2xl lg:text-4xl mb-5 font-semibold">
              Completed Trips
            </h2>
            <div className="text-xl">
              <p>Taught over 2000 girls in over 20 high schools globally</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <h2 className="text-2xl lg:text-4xl font-semibold mb-5">
              Future Trips
            </h2>
            <div className="text-xl">
              <p>Future trips related information </p>
              <p>eg. Johannesburg, South Africa </p>
              <p> Lagos, Nigeria </p>
              <p>Bogota, Columbia</p>
              <p> Auroville, India </p>
              <p>Bijapur, India</p>
              <p>Dhaka, Bangladesh</p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="text-xl mt-10">
              <p>Interested in Volunteering to be part of this mission?</p>
              <p>
                You can be a mentor or part of the team helping us scale the
                reach of Project Artha.
              </p>
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: 3, backgroundColor: "#2196f3" }}
                onClick={() => navigate("/mentorregister")}
              >
                Login as Mentor
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Impact;
