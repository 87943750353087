// import React, { useState, useEffect } from "react";
// import axios from "axios";

// const VideoPlayer = () => {
//   const [videos, setVideos] = useState([]);
//   const [currentVideo, setCurrentVideo] = useState("");

//   useEffect(() => {
//     const fetchVideos = async () => {
//       try {
//         const response = await axios.get(
//           "http://localhost:4000/api/course/courses"
//         );
//         setVideos(response.data.playlist_links);
//         setCurrentVideo(response.data[0]?.playlist_links[0]);
//       } catch (error) {
//         console.error("Error fetching videos:", error);
//       }
//     };
//     fetchVideos();
//   }, []);

//   const handleVideoClick = (videoLink) => {
//     setCurrentVideo(videoLink);
//   };

//   return (
//     <div className="flex flex-row justify-center">
//       <div className="w-1/2 p-4">
//         <iframe
//           width="100%"
//           height="400"
//           src={currentVideo}
//           title="Video Player"
//           frameBorder="0"
//           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//           allowFullScreen
//         ></iframe>
//       </div>
//       <div className="w-1/2 p-4">
//         <h2 className="text-xl font-bold mb-4">Videos</h2>
//         <ul>
//           {videos.map((video, index) => (
//             <li
//               key={index}
//               className="cursor-pointer"
//               onClick={() => handleVideoClick(video.playlist_links[0])}
//             >
//               {video.title}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default VideoPlayer;
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Button,
// } from "@mui/material";
// import { Link } from "react-router-dom";

// const CoursesTable = () => {
//   const [courses, setCourses] = useState([]);

//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await axios.get(
//           "http://localhost:4000/api/course/courses"
//         );
//         setCourses(response.data);
//       } catch (error) {
//         console.error("Error fetching courses:", error);
//       }
//     };
//     fetchCourses();
//   }, []);

//   return (
//     <div>
//       <h2>All Courses</h2>
//       <TableContainer component={Paper} className="mx-8 my-8">
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Title</TableCell>
//               <TableCell>Description</TableCell>
//               <TableCell>Duration</TableCell>
//               <TableCell>Platform</TableCell>
//               <TableCell>Action</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {courses.map((course) => (
//               <TableRow key={course.course_id}>
//                 <TableCell>{course.title}</TableCell>
//                 <TableCell>{course.description}</TableCell>
//                 <TableCell>{course.duration}</TableCell>
//                 <TableCell>{course.platform_name}</TableCell>
//                 <TableCell>
//                   <Button variant="contained">
//                     <Link to={`/play/${course.course_id}`}>Learn Course</Link>
//                   </Button>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </div>
//   );
// };

// export default CoursesTable;
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ThemeProvider } from "@mui/system";
import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1F0954",
      // Your primary color
    },
    typography: {
      fontFamily: "Poppins, sans-serif",
    },
  },
});

const CoursesTable = () => {
  const [courses, setCourses] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          "http://localhost:4000/api/course/courses"
        );
        setCourses(response.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ fontFamily: "Poppins" }} className="container mx-auto px-4">
        <h2 className="text-2xl font-bold mb-4 ">All Courses</h2>
        <TableContainer
          sx={{ fontFamily: "Poppins" }}
          component={Paper}
          className="mb-8"
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                  Title
                </TableCell>
                <TableCell sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                  Description
                </TableCell>
                <TableCell sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                  Duration
                </TableCell>
                <TableCell sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                  Platform
                </TableCell>
                <TableCell sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courses
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((course) => (
                  <TableRow
                    key={course.course_id}
                    className="hover:bg-gray-100 "
                  >
                    <TableCell sx={{ fontFamily: "Poppins" }}>
                      {course.title}
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Poppins" }}>
                      {course.description}
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Poppins" }}>
                      {course.duration}
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Poppins" }}>
                      {course.platform_name}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        className="bg-blue-500 hover:bg-blue-700"
                      >
                        <Link
                          to={`/play/${course.course_id}`}
                          className="text-white font-poppins"
                        >
                          Learn Course
                        </Link>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={courses.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          className="flex justify-end"
        />
      </div>
    </ThemeProvider>
  );
};

export default CoursesTable;
