import "./App.css";
import SideNav from "./Components/SideNav/Sidenav";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Content from "./Components/Home/Home";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import MentorRegister from "./Components/Mentor/MentorRegister";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Donate from "./Components/Donate/Donate";
import Home from "./Components/Home/Home";
import Resources from "./Components/Resources/Resources";
import Login from "./Components/Login/login";
import Course from "./Components/Course/course";
import MentorLogin from "./Components/Mentor/MentorLogin";
import MentorDashboard from "./Components/Mentor/MentorDashboard";
import CreateCourse from "./Components/Course/createcourse";
import Impact from "./Components/Impact/Impact";
import MentorAdmin from "./Components/Mentor/MentorAdmin";
import StudentAdmin from "./Components/Mentor/StudentAdmin";
import CategoryAdmin from "./Components/Mentor/CategoryAdmin";
import AdminManagement from "./Components/Mentor/AdminManagement";
import Finance from "./Components/Resources/finance";
import Education from "./Components/Resources/education";
import SuccessStories from "./Components/Resources/successStories";
import KhanAcademy from "./Components/Resources/KhanAcademy";
import Youtube from "./Components/Resources/YouTube";
import Show from "./Components/Resources/ShowVideo";
import Gallery from "./Components/Gallery/Gallery";
import TermsAndConditions from "./Components/TermsConditions";

function App() {
  return (
    <>
      <Router>
        <Navbar></Navbar>
        <Routes>
          {/* <Route path="/" element={<SideNav></SideNav>}></Route> */}
          <Route path="/" element={<Home></Home>}></Route>
          <Route path="/contact" element={<Contact></Contact>}></Route>
          <Route path="/resources" element={<Resources></Resources>}></Route>
          <Route path="/finance" element={<Finance></Finance>}></Route>
          <Route
            path="/khan_academy"
            element={<KhanAcademy></KhanAcademy>}
          ></Route>

          <Route path="/education" element={<Education></Education>}></Route>
          <Route
            path="/success_stories"
            element={<SuccessStories></SuccessStories>}
          ></Route>
          <Route path="/impact" element={<Impact></Impact>}></Route>
          <Route path="/aboutus" element={<About></About>}></Route>
          <Route
            path="/terms-and-condition"
            element={<TermsAndConditions />}
          ></Route>
          <Route path="/donate" element={<Donate></Donate>}></Route>
          <Route path="/course" element={<Course></Course>}></Route>
          <Route path="/gallery" element={<Gallery />}></Route>
          <Route
            path="/create-course"
            element={<CreateCourse></CreateCourse>}
          ></Route>
          <Route path="/login" element={<Login></Login>}></Route>
          <Route
            path="/mentorlogin"
            element={<MentorLogin></MentorLogin>}
          ></Route>
          <Route
            path="/mentordashboard"
            element={<MentorDashboard></MentorDashboard>}
          ></Route>
          <Route
            path="/mentorregister"
            element={<MentorRegister></MentorRegister>}
          ></Route>
          <Route path="/mentoradmin" element={<MentorAdmin />}></Route>
          <Route path="/studentadmin" element={<StudentAdmin />}></Route>
          <Route path="/categoryadmin" element={<CategoryAdmin />}></Route>
          <Route path="/admindata" element={<AdminManagement />}></Route>
          <Route path="/youtube" element={<Youtube />}></Route>
          <Route path="/play/:courseId" element={<Show />}></Route>
        </Routes>
        <Footer></Footer>
      </Router>

      {/* <Tail></Tail> */}
      {/* <Tasach></Tasach> */}
    </>
  );
}

export default App;
