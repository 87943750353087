import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Carousel from "react-material-ui-carousel"; // Import Carousel component
import "./About.css"; // Import CSS file for additional styling
import { Grid } from "@mui/material";
import FounderPhoto from "./Founder-Reva-Galagali.jpg";
import WhoAreWe from "./Whoarewe.jpg";
function About() {
  const founderDescription = `Reva Galagali, the driving force behind Project Artha, embarked on her journey as a passionate student at Thomas Jefferson High School for Science and Technology (TJHSST). With a keen interest in empowerment through knowledge, Reva's path was shaped by her deep family values and the inspirational role of financial literacy in achieving independence. Her experience working with personal finance managers catalyzed her mission to democratize financial education, highlighting the critical need for accessible financial wisdom among youth. At the heart of Reva's endeavors is a commitment to fostering equality and opportunity, aiming to equip young individuals with the financial tools necessary for a prosperous future.`;

  const arthaDescription = `Artha is a term used in Hinduism, Buddhism, and Jainism to describe one of the four goals of human life. Simply, it refers to the "means or purpose of life." Artha can be understood as an attitude and capability that helps us sustain ourselves, thrive, and maintain our freedom. Artha is not an ultimate destination or an endless pursuit of amassing wealth without purpose. Instead, it is best described as the pursuit of activities and resources necessary for a joyful and fulfilling life. It encompasses economic prosperity, personal security, and good health for oneself and those we are responsible for. Rooted in these principles, Project Artha provides the opportunity for all youth to pursue an independent life with informed decisions. Helped over 100 girls and 40 boys from 6 high schools across India.`;

  return (
    <div className="bg-[#F5F5F5]">
      <Box sx={{ fontFamily: "Poppins" }} className="container">
        {/* About Founder
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "40px" }}>
        <Box sx={{ flex: "1", marginRight: "50px", textAlign: "center" }}>
          <img
            src="/images/meetceo.png"
            alt="Founder"
            className="founder-photo mr-10"
            style={{
              borderRadius: "50%",
              width: "200px",
              height: "200px",
              objectFit: "cover",
            }}
          />
          <Typography
            variant="subtitle1"
            sx={{ marginTop: "10px", fontWeight: "bold" }}
          >
            Meet our founder: Miss Reva Galagali
          </Typography>
        </Box>
        <Box sx={{ flex: "2" }}>
          <Typography variant="body1">{founderDescription}</Typography>
        </Box>
      </Box> */}
        <h1 className="font-bold text-3xl lg:text-5xl my-5 text-center">
          About Us
        </h1>
        <Grid container className="pt-5">
          <Grid xs={12} md={4}>
            <img
              className="rounded-lg mt-4"
              src={FounderPhoto}
              alt="Founder Image"
            />
          </Grid>
          <Grid xs={12} className="lg:px-5" md={8}>
            <h4 className="text-2xl my-2 font-semibold ">Meet Our Founder</h4>
            <h3 className="text-3xl lg:text-4xl font-bold text-[#6F00FE]">
              Miss Reva Galagali
            </h3>
            <div className="mt-2 text-justify">
              <Typography variant="body">{founderDescription}</Typography>
            </div>
          </Grid>
        </Grid>

        {/* Main Section */}
        <Grid container spacing={4} sx={{ pt: 5, mb: 5 }} alignItems="center">
          {/* Write-up on the Left */}
          <Grid item xs={12} md={6} className="text-center mt-10">
            <Typography
              variant="h3"
              sx={{ fontWeight: "bold", color: "#226575" }}
            >
              Artha
            </Typography>
            <div
              className="md:text-xl py-4"
              style={{ maxWidth: "100%", margin: "5px" }}
            >
              <Typography
                variant="body1"
                paragraph
                fontWeight="bold"
                className="py-2"
              >
                "Artha is a term used in Hinduism, Buddhism, and Jainism to
                describe one of the four goals of human life. Simply, it refers
                to the "means or purpose of life."
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="py-2 text-justify"
              >
                Artha can be understood as an attitude and capability that helps
                us sustain ourselves, thrive, and maintain our freedom. Artha is
                not an ultimate destination or an endless pursuit of amassing
                wealth without purpose. Instead, it is best described as the
                pursuit of activities and resources necessary for a joyful and
                fulfilling life. It encompasses economic prosperity, personal
                security, and good health for oneself and those we are
                responsible for.
              </Typography>
              <Typography variant="body1" className="text-justify">
                Rooted in these principles, Project Artha provides the
                opportunity for all youth to pursue an independent life with
                informed decisions.
              </Typography>
            </div>
          </Grid>

          {/* Carousel on the Right */}
          <Grid item xs={12} md={6}>
            <Carousel autoPlay={true} animation="slide">
              <img
                src="/images/caro1.jpg"
                alt="Carousel Image 1"
                style={{ width: "100%", height: "auto" }}
              />
              <img
                src="/images/caro2.jpg"
                alt="Carousel Image 2"
                style={{ width: "100%", height: "auto" }}
              />
              <img
                src="/images/caro3.jpg"
                alt="Carousel Image 3"
                style={{ width: "100%", height: "auto" }}
              />
              <img
                src="/images/caro4.jpg"
                alt="Carousel Image 4"
                style={{ width: "100%", height: "auto" }}
              />
            </Carousel>
          </Grid>
        </Grid>

        <Grid
          spacing={4}
          sx={{
            py: 5,
            backgroundColor: "#ECF3FE",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            mx: 0, // No horizontal margins
          }}
          alignItems="center"
        >
          {/* Image on the Left */}
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: { xs: "100%", md: "auto" },
              px: { xs: 2, md: 0 },
            }}
          >
            <img
              src={WhoAreWe}
              className="px-2"
              alt="Who We Are"
              style={{ width: "100%", height: "auto" }}
            />
          </Grid>

          {/* Write-up on the Right */}
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
              textAlign: { xs: "center", md: "left" },
              pt: 4,
              px: { xs: 2, md: 2 },
            }}
          >
            <Typography
              variant="h3"
              className="flex items-center justify-center"
              sx={{
                fontWeight: "bold",
                color: "#226575",
                textAlign: "center",
                width: "100%",
              }}
            >
              Who Are We?
            </Typography>
            <div className="pt-5 pb-2">
              <Typography
                variant="body1"
                className="md:text-xl text-justify"
                // sx={{ px: 2 }}
              >
                Project Artha aims to empower young women by equipping them with
                the necessary skills to practice artha throughout their lives.
                Our volunteers embark on mission trips to high schools around
                the world. They teach a comprehensive curriculum on financial
                literacy. The curriculum is curated to the geography and culture
                and local environment where taught. Our commitment doesn't end
                with these trips, we maintain an ongoing relationship with our
                students, supporting them as they navigate adulthood.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default About;
