import React, { useState } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  ThemeProvider,
  createTheme,
  CardMedia,
  CardActions,
  CardActionArea,
  Button,
} from "@mui/material";

const Finance = () => {
  const [currentPdf, setCurrentPdf] = useState("");
  const [showIframe, setShowIframe] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1F0954", // Your primary color
      },
    },
  });

  const handleLearnClick = (pdfUrl) => {
    setCurrentPdf(pdfUrl);
    setShowIframe(true);
  };

  const handleBackClick = () => {
    setShowIframe(false);
    setCurrentPdf("");
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="text-center bg-[#F5F5F5]">
        <div
          // className="container"
          style={{ padding: "16px", minHeight: "100vh" }}
        >
          {!showIframe ? (
            <>
              <h1 className="text-center font-bold text-3xl lg:text-5xl my-5">
                Finance
              </h1>
              <Grid container className="justify-center">
                <Grid container spacing={4} className="justify-center">
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                        "&:hover": {
                          transform: "scale(1.05)", // Scale to 1.1 on hover
                        },
                      }}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="140"
                          image="images/budgeting.jpg"
                          alt="budgeting"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            Budgeting
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Learn the basics of budgeting to manage your
                            finances effectively.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions className="justify-center">
                        <a
                          className="abc"
                          onClick={() =>
                            handleLearnClick(
                              "https://img1.wsimg.com/blobby/go/a0635672-0d21-45db-b668-4f23935e3d30/Day%20-%201%20-%20Intro%20%26%20Budgeting.pdf"
                            )
                          }
                        >
                          <span>Learn</span>
                        </a>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                        "&:hover": {
                          transform: "scale(1.05)", // Scale to 1.1 on hover
                        },
                      }}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="140"
                          image="images/investing.jpg"
                          alt="investing"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            Invest Your Savings
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Discover strategies for investing your savings to
                            build wealth.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions className="justify-center">
                        <a
                          className="abc"
                          onClick={() =>
                            handleLearnClick(
                              "https://img1.wsimg.com/blobby/go/a0635672-0d21-45db-b668-4f23935e3d30/Day%203%20-%20SAVING%20AND%20INVESTING.pdf"
                            )
                          }
                        >
                          <span>Learn</span>
                        </a>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                        "&:hover": {
                          transform: "scale(1.05)", // Scale to 1.1 on hover
                        },
                      }}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="140"
                          image="images/interest.jpg"
                          alt="interest"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            Interest
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Understand how compounding interest works to
                            maximize your earnings.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions className="justify-center">
                        <a
                          className="abc"
                          onClick={() =>
                            handleLearnClick(
                              "https://img1.wsimg.com/blobby/go/a0635672-0d21-45db-b668-4f23935e3d30/Day%202%20-%20COMPOUNDING%20INTEREST.pdf"
                            )
                          }
                        >
                          <span>Learn</span>
                        </a>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                        "&:hover": {
                          transform: "scale(1.05)", // Scale to 1.1 on hover
                        },
                      }}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="140"
                          image="images/loans.jpg"
                          alt="loans"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            Loans
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Learn about different types of loans and how to
                            manage them.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions className="justify-center">
                        <a
                          className="abc"
                          onClick={() =>
                            handleLearnClick(
                              "https://img1.wsimg.com/blobby/go/a0635672-0d21-45db-b668-4f23935e3d30/Day%203%20-%20LOANS.pdf"
                            )
                          }
                        >
                          <span>Learn</span>
                        </a>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                        "&:hover": {
                          transform: "scale(1.05)", // Scale to 1.1 on hover
                        },
                      }}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="140"
                          image="images/retirement.jpg"
                          alt="retirement"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            Retirement
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Plan for a secure and comfortable retirement.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions className="justify-center">
                        <a
                          className="abc "
                          onClick={() =>
                            handleLearnClick(
                              "https://img1.wsimg.com/blobby/go/a0635672-0d21-45db-b668-4f23935e3d30/Day%204%20-%20RETIREMENT.pdf"
                            )
                          }
                        >
                          <span>Learn</span>
                        </a>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <div className="">
              <Button
                onClick={handleBackClick}
                variant="contained"
                color="primary"
                className="mb-4 flex float-end "
              >
                Back
              </Button>
              <iframe
                src={currentPdf}
                width="100%"
                height="550px"
                title="PDF Viewer"
                frameBorder="0"
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Finance;
