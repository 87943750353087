// import React, { useState } from "react";
// import {
//   Typography,
//   Button,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   TextField,
//   ThemeProvider,
//   createTheme,
// } from "@mui/material";
// import axios from "axios";
// import { useForm } from "react-hook-form";
// import { AccountCircle, Email, Phone, LocationOn } from "@mui/icons-material";

// function Donate() {
//   const [open, setOpen] = useState(false);
//   const { register, handleSubmit } = useForm();

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const onSubmit = async (data) => {
//     try {
//       const response = await axios.post("https://projectarthabackend.ajinkyatechnologies.com//api/donation/create-donation", data);
//       console.log(response.data);
//       // Optionally, you can reset the form state or show a success message here
//       handleClose(); // Close the modal after form submission
//     } catch (error) {
//       console.error("Error creating donation:", error);
//       // Handle error or show error message to the user
//     }
//   };

//   // Define custom theme for the form
//   const theme = createTheme({
//     palette: {
//       primary: {
//         main: "#1F0954", // Your primary color
//       },
//     },
//   });

//   return (
//     <ThemeProvider theme={theme}>
//       <div className="min-h-screen text-white donate-section top-[-1]">
//         <div className="container">
//           <div className="text-center">
//             <h1 className="font-bold text-4xl lg:text-5xl mb-10">
//               Help Our Course
//             </h1>
// <p className="text-2xl font-semibold lg:text-3xl">
//   Your support and contributions will enable us to meet our goals
//   and improve conditions. Your generous donation will fund our
//   mission.
// </p>
//             <Button
//               size="large"
//               sx={{ fontWeight: "bold", my: "20px" }}
//               variant="contained"
//               onClick={handleOpen}
//             >
//               Donate Us
//             </Button>
//           </div>
//           <Dialog open={open} onClose={handleClose}>
//             <DialogTitle>Donate Now</DialogTitle>
//             <DialogContent>
//               <form onSubmit={handleSubmit(onSubmit)} className="donation-form">
//                 <TextField
//                   fullWidth
//                   label="Name"
//                   variant="outlined"
//                   margin="normal"
//                   InputProps={{
//                     startAdornment: <AccountCircle color="primary" />,
//                   }}
//                   {...register("name")}
//                 />
//                 <TextField
//                   fullWidth
//                   label="Email"
//                   variant="outlined"
//                   margin="normal"
//                   InputProps={{
//                     startAdornment: <Email color="primary" />,
//                   }}
//                   {...register("email")}
//                 />
//                 <TextField
//                   fullWidth
//                   label="Phone Number"
//                   variant="outlined"
//                   margin="normal"
//                   InputProps={{
//                     startAdornment: <Phone color="primary" />,
//                   }}
//                   {...register("phone_number")}
//                 />
//                 <TextField
//                   fullWidth
//                   label="Address"
//                   variant="outlined"
//                   margin="normal"
//                   InputProps={{
//                     startAdornment: <LocationOn color="primary" />,
//                   }}
//                   {...register("address")}
//                 />
//                 <TextField
//                   fullWidth
//                   label="City"
//                   variant="outlined"
//                   margin="normal"
//                   InputProps={{
//                     startAdornment: <LocationOn color="primary" />,
//                   }}
//                   {...register("city")}
//                 />
//                 <TextField
//                   fullWidth
//                   label="Amount"
//                   variant="outlined"
//                   margin="normal"
//                   InputProps={{
//                     startAdornment: <AccountCircle color="primary" />,
//                   }}
//                   {...register("amount")}
//                 />
//                 <TextField
//                   fullWidth
//                   label="Cause"
//                   variant="outlined"
//                   margin="normal"
//                   InputProps={{
//                     startAdornment: <LocationOn color="primary" />,
//                   }}
//                   {...register("cause")}
//                 />
//                 <TextField
//                   fullWidth
//                   label="Description"
//                   variant="outlined"
//                   margin="normal"
//                   multiline
//                   rows={4}
//                   {...register("description")}
//                 />
//               </form>
//             </DialogContent>
//             <DialogActions>
//               <Button onClick={handleClose}>Cancel</Button>
//               <Button
//                 type="submit"
//                 form="donation-form"
//                 variant="contained"
//                 sx={{ backgroundColor: "#1F0954", color: "#ffffff" }}
//               >
//                 Submit
//               </Button>
//             </DialogActions>
//           </Dialog>
//         </div>
//       </div>
//     </ThemeProvider>
//   );
// }

// export default Donate;
import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
  Card,
  CardContent,
  InputAdornment,
  ThemeProvider,
  createTheme,
  useMediaQuery,
  Alert,
  AlertTitle,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GoogleIcon from "@mui/icons-material/Google";
import axios from "axios";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    h1: {
      fontSize: "2.5rem",
      "@media (max-width:600px)": {
        fontSize: "1.8rem",
      },
    },
    body1: {
      fontSize: "1rem",
      "@media (max-width:600px)": {
        fontSize: "0.9rem",
      },
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: "16px",
          width: "calc(100% - 32px)",
          maxWidth: "600px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
        },
      },
    },
  },
});

const DonationComponent = () => {
  const [open, setOpen] = useState(false);
  const [donationAmount, setDonationAmount] = useState(1);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = (amount) => {
    setOpen(true);
    if (amount) setDonationAmount(amount);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCustomDonation = async () => {
    try {
      const response = await axios.post(
        "http://localhost:4000/api/donation/initiate-payment",
        {
          amount: donationAmount,
        }
      );
      window.location.href = response.data.paypalRedirectUrl;
    } catch (error) {
      console.error("Error initiating PayPal payment:", error);
    }
  };

  const donationAmounts = [25, 50, 150, 200];

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: "85vh",
          bgcolor: "#F5F5F5",
          p: { xs: 2, sm: 4 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            mb: { xs: 4, sm: 6 },
            fontSize: { xs: "2.5rem", sm: "3rem" },
          }}
        >
          Help Project Artha
        </Typography>

        <Grid
          container
          spacing={4}
          sx={{
            maxWidth: "1200px",
            // mx: "auto",
            width: "100%",
          }}
        >
          {/* Left Card - Description */}
          <Grid item xs={12} md={6}>
            <Card
              elevation={3}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "justify",
              }}
            >
              <CardContent>
                {/* <Typography variant="h5" fontWeight="bold" gutterBottom>
                  About Us
                </Typography> */}
                <Typography
                  variant="body1"
                  paragraph
                  style={{ padding: "10px" }}
                >
                  Project Artha is a Not-for-profit Organization with 501 (c)(3)
                  status and Tax ID: 59-3527607. Help Project Artha make a
                  difference. Our organization is committed to teaching young
                  women on finances. With your support, we can make a positive
                  impact on the world. Join us today! Your support and
                  contributions will enable us to meet our goals and improve
                  conditions. Your generous donation will fund our mission.
                </Typography>
                {/* <Alert severity="info" sx={{ mt: 2 }}>
                  <AlertTitle>Make an Impact</AlertTitle>
                  With your support, we can make a positive impact on the world.
                  Join us today! Your support and contributions will enable us
                  to meet our goals and improve conditions.
                </Alert> */}
              </CardContent>
            </Card>
          </Grid>

          {/* Right Card - Donation Options */}
          <Grid item xs={12} md={6}>
            <Card
              elevation={3}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardContent>
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                  Choose Your Donation
                </Typography>

                {/* Top Row Buttons */}
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  {donationAmounts.slice(0, 2).map((amount) => (
                    <Grid item xs={6} key={amount}>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleOpen(amount)}
                        sx={{
                          py: 3,
                          fontSize: "1.25rem",
                          bgcolor: "#25CFBB",
                          "&:hover": {
                            bgcolor: "#20B09F",
                          },
                        }}
                      >
                        ${amount}
                      </Button>
                    </Grid>
                  ))}
                </Grid>

                {/* Bottom Row Buttons */}
                <Grid container spacing={2} sx={{ mb: 4 }}>
                  {donationAmounts.slice(2, 4).map((amount) => (
                    <Grid item xs={6} key={amount}>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleOpen(amount)}
                        sx={{
                          py: 3,
                          fontSize: "1.25rem",
                          bgcolor: "#25CFBB",
                          "&:hover": {
                            bgcolor: "#20B09F",
                          },
                        }}
                      >
                        ${amount}
                      </Button>
                    </Grid>
                  ))}
                </Grid>

                {/* Main Donate Button */}
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => handleOpen()}
                  sx={{
                    // py: 4,
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    bgcolor: "#9848FF",
                    "&:hover": {
                      bgcolor: "#8425FF",
                    },
                  }}
                >
                  Donate Now
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Donation Dialog */}
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            sx: {
              p: { xs: 2, sm: 3 },
              width: { xs: "95%", sm: "auto" },
              maxWidth: { xs: "95%", sm: "600px" },
            },
          }}
        >
          <DialogTitle
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.5rem" },
              fontWeight: "bold",
            }}
          >
            Donation Amount
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ mb: 2, fontSize: { xs: "1rem", sm: "1.1rem" } }}>
              Donation Amount: ${donationAmount}
            </Typography>

            <TextField
              label="Custom Amount"
              variant="outlined"
              type="number"
              value={donationAmount}
              onChange={(e) =>
                setDonationAmount(Math.max(parseInt(e.target.value) || 1, 1))
              }
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{ min: 1 }}
              sx={{ mb: 3 }}
            />

            <Grid container spacing={2} sx={{ mb: 3 }}>
              {donationAmounts.map((amount) => (
                <Grid item xs={6} sm={3} key={amount}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => setDonationAmount(amount)}
                    sx={{
                      py: 2,
                      fontSize: { xs: "0.9rem", sm: "1rem" },
                      bgcolor: "#3A41F8",
                      "&:hover": {
                        bgcolor: "#171FE4",
                      },
                    }}
                  >
                    ${amount}
                  </Button>
                </Grid>
              ))}
            </Grid>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  gap: 1,
                  color: "black",
                  borderColor: "black",
                  "&:hover": {
                    borderColor: "black",
                    bgcolor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                <GoogleIcon />
                <Typography>Pay</Typography>
              </Button>

              {["PayPal", "Credit/Debit"].map((method) => (
                <Button
                  key={method}
                  variant="contained"
                  fullWidth
                  sx={{
                    py: 2,
                    fontWeight: "bold",
                    fontSize: { xs: "0.9rem", sm: "1rem" },
                    bgcolor: "#3A41F8",
                    "&:hover": {
                      bgcolor: "#171FE4",
                    },
                  }}
                >
                  {method}
                </Button>
              ))}
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} sx={{ color: "black" }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default DonationComponent;
