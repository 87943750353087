// import React from "react";
// import {
//   Typography,
//   Grid,
//   Card,
//   CardContent,
//   ThemeProvider,
//   createTheme,
//   CardMedia,
//   CardActions,
//   CardActionArea,
//   Button,
// } from "@mui/material";
// import { Link } from "react-router-dom";
// import { fontFamily } from "@mui/system";

// // import { styled } from "@mui/system";
// // const ResourceCard = styled(Card)(({ theme }) => ({
// //   height: "100%",
// //   display: "flex",
// //   flexDirection: "column",
// //   justifyContent: "space-between",
// //   borderRadius: theme.spacing(2),
// //   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
// //   transition: "transform 0.3s ease",
// //   "&:hover": {
// //     transform: "translateY(-5px)",
// //   },
// // }));

// function Resources() {
//   // Define custom theme for the resource section
//   const theme = createTheme({
//     palette: {
//       primary: {
//         main: "#1F0954", // Your primary color
//       },
//     },
//   });

//   return (
//     <ThemeProvider theme={theme}>
//       <div className="text-center bg-[#F5F5F5]">
//         <div
//           className="container"
//           style={{
//             padding: "10",
//             minHeight: "88vh",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             flexDirection: "column",
//           }}
//         >
//           <h1 className="font-bold text-3xl lg:text-5xl my-5 p-3">Resources</h1>
//           <Grid container spacing={4} className="justify-between">
//             <Grid item xs={12} sm={6} md={4}>
//               <Card
//                 sx={{
//                   transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
//                   "&:hover": {
//                     transform: "scale(1.05)", // Scale to 1.1 on hover
//                   },
//                   fontFamily: "Poppins",
//                 }}
//               >
//                 <CardActionArea>
//                   <CardMedia
//                     component="img"
//                     height="140"
//                     image="images/finance1.jpg"
//                     alt="green iguana"
//                   />
//                   <CardContent>
//                     <Typography
//                       sx={{ fontFamily: "Poppins" }}
//                       gutterBottom
//                       variant="h5"
//                       component="div"
//                     >
//                       Finance
//                     </Typography>
//                     <Typography
//                       sx={{ fontFamily: "Poppins" }}
//                       variant="body2"
//                       color="text.secondary"
//                     >
//                       Lizards are a widespread group of squamate reptiles, with
//                       over 6,000 species, ranging across all continents except
//                       Antarctica
//                     </Typography>
//                   </CardContent>
//                 </CardActionArea>
//                 <CardActions className="justify-center">
//                   <div>
//                     <Link sx={{ fontFamily: "Poppins" }} to="/finance">
//                       <div class="">
//                         <a className="abc">
//                           <span>Explore</span>
//                         </a>
//                       </div>
//                     </Link>
//                   </div>
//                 </CardActions>
//               </Card>
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <Card
//                 sx={{
//                   transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
//                   "&:hover": {
//                     transform: "scale(1.05)", // Scale to 1.1 on hover
//                   },
//                 }}
//               >
//                 <CardActionArea>
//                   <CardMedia
//                     component="img"
//                     height="140"
//                     image="images/education.jpg"
//                     alt="green iguana"
//                   />
//                   <CardContent>
//                     <Typography
//                       sx={{ fontFamily: "Poppins" }}
//                       gutterBottom
//                       variant="h5"
//                       component="div"
//                     >
//                       Education
//                     </Typography>
//                     <Typography
//                       sx={{ fontFamily: "Poppins" }}
//                       variant="body2"
//                       color="text.secondary"
//                     >
//                       Lizards are a widespread group of squamate reptiles, with
//                       over 6,000 species, ranging across all continents except
//                       Antarctica
//                     </Typography>
//                   </CardContent>
//                 </CardActionArea>
//                 <CardActions className="justify-center">
//                   <div>
//                     <Link to="/education">
//                       <div class="">
//                         <a className="abc">
//                           <span>Explore</span>
//                         </a>
//                       </div>
//                     </Link>
//                   </div>
//                 </CardActions>
//               </Card>
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <Card
//                 sx={{
//                   transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
//                   "&:hover": {
//                     transform: "scale(1.05)", // Scale to 1.1 on hover
//                   },
//                 }}
//               >
//                 <CardActionArea>
//                   <CardMedia
//                     component="img"
//                     height="140"
//                     image="images/success_story.jpg"
//                     alt="green iguana"
//                   />
//                   <CardContent>
//                     <Typography
//                       sx={{ fontFamily: "Poppins" }}
//                       gutterBottom
//                       variant="h5"
//                       component="div"
//                     >
//                       Success Stories
//                     </Typography>
//                     <Typography
//                       sx={{ fontFamily: "Poppins" }}
//                       variant="body2"
//                       color="text.secondary"
//                     >
//                       Lizards are a widespread group of squamate reptiles, with
//                       over 6,000 species, ranging across all continents except
//                       Antarctica
//                     </Typography>
//                   </CardContent>
//                 </CardActionArea>
//                 <CardActions className="justify-center">
//                   <div>
//                     <Link to="/success_stories">
//                       <div class="">
//                         <a className="abc">
//                           <span>Explore</span>
//                         </a>
//                       </div>
//                     </Link>
//                   </div>
//                 </CardActions>
//               </Card>
//             </Grid>

//             {/* Add more Grid items for additional resources */}
//           </Grid>
//         </div>
//       </div>
//     </ThemeProvider>
//   );
// }

// export default Resources;

import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  ThemeProvider,
  createTheme,
  CardMedia,
  CardActions,
  CardActionArea,
  Button,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import pdfPath from "./RS2_final_paper.pdf";

// Import pdfjs-dist and set workerSrc from npm module path
import * as pdfjsLib from "pdfjs-dist/build/pdf";
// Ensure the workerSrc is set correctly
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@2.15.349/build/pdf.worker.min.js`;
const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

function Resources() {
  const [pdfPageNumber, setPdfPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1F0954",
      },
    },
  });

  const resources = [
    { title: "Finance", route: "finance", image: "images/finance1.jpg" },
    { title: "Education", route: "education", image: "images/education.jpg" },
    {
      title: "Success Story",
      route: "success_stories",
      image: "images/success_story.jpg",
    },
  ];

  // Function to render PDF page
  const renderPage = async (pageNumber, pdf) => {
    try {
      const page = await pdf.getPage(pageNumber);
      const canvas = document.getElementById("pdf-canvas");
      const context = canvas.getContext("2d");

      const viewport = page.getViewport({ scale: 1.5 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;
    } catch (err) {
      setError("Error rendering PDF page");
      setLoading(false);
    }
  };

  // Load PDF
  // Load PDF
  useEffect(() => {
    const loadPDF = async () => {
      try {
        setLoading(true);
        const loadingTask = pdfjsLib.getDocument(pdfPath);
        const pdf = await loadingTask.promise;
        setTotalPages(pdf.numPages);
        await renderPage(pdfPageNumber, pdf);
        setLoading(false);
      } catch (err) {
        console.error("Error loading PDF:", err);
        setError("Error loading PDF");
        setLoading(false);
      }
    };
    loadPDF();
  }, [pdfPageNumber]);

  // Handle page changes
  const handlePageChange = async (newPageNumber) => {
    if (newPageNumber >= 1 && newPageNumber <= totalPages) {
      setLoading(true);
      setPdfPageNumber(newPageNumber);
      const pdf = await pdfjsLib.getDocument(pdfPath).promise;
      await renderPage(newPageNumber, pdf);
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="text-center"
        sx={{ bgcolor: "#F5F5F5", minHeight: "88vh", py: 4 }}
      >
        <Box sx={{ maxWidth: 1200, mx: "auto", px: 3 }}>
          <Typography variant="h2" component="h1" gutterBottom>
            Resources
          </Typography>
          <Typography variant="h4" gutterBottom>
            Employment of Women in the Financial Sector
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            A paper written by the founder and a partner dives into the US
            census to find gender disparities in the financial sector.
          </Typography>

          {/* PDF Viewer */}
          <Paper elevation={3} sx={{ my: 4, p: 3 }}>
            {loading && (
              <Box display="flex" justifyContent="center" my={4}>
                <CircularProgress />
              </Box>
            )}
            {error && (
              <Typography color="error" align="center">
                {error}
              </Typography>
            )}
            <canvas
              id="pdf-canvas"
              style={{ maxWidth: "100%", height: "auto" }}
            ></canvas>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button
                variant="contained"
                disabled={pdfPageNumber <= 1 || loading}
                onClick={() => handlePageChange(pdfPageNumber - 1)}
              >
                Previous
              </Button>
              <Typography>
                Page {pdfPageNumber} of {totalPages || "..."}
              </Typography>
              <Button
                variant="contained"
                disabled={pdfPageNumber >= totalPages || loading}
                onClick={() => handlePageChange(pdfPageNumber + 1)}
              >
                Next
              </Button>
            </Box>

            <Box sx={{ mt: 2, textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                component="a"
                href={pdfPath}
                download
              >
                Download PDF
              </Button>
            </Box>
          </Paper>

          {/* Resource Cards */}
          <Grid container spacing={4} justifyContent="center">
            {resources.map((resource, index) => (
              <Grid item xs={12} sm={6} md={4} key={resource.route}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={cardVariants}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Card
                    sx={{
                      transition: "transform 0.4s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="140"
                        image={resource.image}
                        alt={resource.title}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {resource.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          This section includes resources related to{" "}
                          {resource.title.toLowerCase()}.
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions sx={{ justifyContent: "center" }}>
                      <Link
                        to={`/${resource.route}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Button variant="contained" color="primary">
                          Explore
                        </Button>
                      </Link>
                    </CardActions>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Resources;
