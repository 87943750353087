import React from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  ThemeProvider,
  createTheme,
  CardMedia,
  CardActions,
  CardActionArea,
  Button,
  // Link,
} from "@mui/material";
import { Link } from "react-router-dom";
import VideoSwitcher from "./VideoSwitcher";
function Education() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1F0954", // Your primary color
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{ fontFamily: "Poppins" }}
        className="text-center bg-[#F5F5F5]"
      >
        <div
          // className="container"
          style={{ padding: "16px", minHeight: "100vh" }}
        >
          <h1 className="text-center font-bold text-3xl lg:text-5xl my-5">
            Education
          </h1>
          <Grid container spacing={4} className="justify-center">
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                  "&:hover": {
                    transform: "scale(1.05)", // Scale to 1.1 on hover
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image="images/KhanAcademy.jpg"
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Khan Academy
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Lizards are a widespread group of squamate reptiles, with
                      over 6,000 species, ranging across all continents except
                      Antarctica
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions className="justify-center">
                  <div>
                    <Link
                      to="/khan_academy"
                      href="https://www.khanacademy.org/"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div class="">
                        <a className="abc">
                          <span>Learn</span>
                        </a>
                      </div>
                    </Link>
                  </div>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                  "&:hover": {
                    transform: "scale(1.05)", // Scale to 1.1 on hover
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image="images/financeIndependent.jpg"
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      YouTube
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Lizards are a widespread group of squamate reptiles, with
                      over 6,000 species, ranging across all continents except
                      Antarctica
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions className="justify-center">
                  <div>
                    <Link
                      // href="https://www.youtube.com/watch?v=q5JWp47z4bY"
                      // href="https://youtu.be/q5JWp47z4bY?si=R8JgYbFCe4mJ4E48"
                      to="/youtube"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div class="">
                        <a className="abc">
                          <span>Learn</span>
                        </a>
                      </div>
                    </Link>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          {/* <div className="App">
            <VideoSwitcher />
          </div> */}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Education;
