import React from "react";
import {
  Typography,
  Box,
  Button,
  Container,
  Paper,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";

const TermsAndConditions = () => {
  const [accepted, setAccepted] = useState(false);

  const handleAcceptChange = (event) => {
    setAccepted(event.target.checked);
  };

  // Animation settings for different sections
  const sectionVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.7 } },
  };

  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Paper elevation={3} sx={{ p: 4, bgcolor: "#f9f9f9" }}>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Terms and Conditions
          </Typography>
        </motion.div>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
        >
          <Typography
            variant="subtitle1"
            align="center"
            color="text.secondary"
            gutterBottom
          >
            Please read these terms and conditions carefully before using the
            Project Artha platform.
          </Typography>
        </motion.div>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          style={{ marginTop: 20 }}
        >
          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography variant="body2" color="text.secondary">
            By accessing or using Project Artha, you agree to comply with these
            Terms and Conditions. These terms apply to all users, volunteers,
            and students. If you disagree with any part of the terms, you may
            not access the platform.
          </Typography>
        </motion.div>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          style={{ marginTop: 20 }}
        >
          <Typography variant="h6" gutterBottom>
            2. Use of the Platform
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Project Artha provides educational content aimed at improving
            financial literacy among young women. The platform's content is for
            informational purposes only, and we do not guarantee any financial
            or career outcomes as a result of participation.
          </Typography>
        </motion.div>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          style={{ marginTop: 20 }}
        >
          <Typography variant="h6" gutterBottom>
            3. Responsibilities of Users
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Users are expected to respect the platform and other members.
            Project Artha reserves the right to suspend or terminate access for
            any violation of these terms, including inappropriate or harmful
            behavior.
          </Typography>
        </motion.div>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          style={{ marginTop: 20 }}
        >
          <Typography variant="h6" gutterBottom>
            4. Intellectual Property
          </Typography>
          <Typography variant="body2" color="text.secondary">
            All content on the Project Artha platform is protected by
            intellectual property laws. Users are prohibited from reproducing,
            distributing, or modifying the content without written consent from
            Project Artha.
          </Typography>
        </motion.div>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          style={{ marginTop: 20 }}
        >
          <Typography variant="h6" gutterBottom>
            5. Limitation of Liability
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Project Artha and its affiliates are not responsible for any direct
            or indirect damages resulting from participation or reliance on the
            information provided through the platform.
          </Typography>
        </motion.div>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          style={{ marginTop: 20 }}
        >
          <Typography variant="h6" gutterBottom>
            6. Modifications
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Project Artha reserves the right to modify these Terms and
            Conditions at any time. Continued use of the platform after
            modifications implies acceptance of the updated terms.
          </Typography>
        </motion.div>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          style={{ marginTop: 20 }}
        >
          <Typography variant="h6" gutterBottom>
            7. Governing Law
          </Typography>
          <Typography variant="body2" color="text.secondary">
            These terms shall be governed by and construed in accordance with
            the laws of the applicable jurisdiction without regard to its
            conflict of law provisions.
          </Typography>
        </motion.div>

        {/* <Box sx={{ mt: 4, textAlign: "center" }}>
          <FormControlLabel
            control={
              <Checkbox checked={accepted} onChange={handleAcceptChange} />
            }
            label="I have read and agree to the Terms and Conditions."
          />
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={!accepted}
              onClick={() => alert("Terms and Conditions accepted.")}
            >
              Accept
            </Button>
          </Box>
        </Box> */}
      </Paper>
    </Container>
  );
};

export default TermsAndConditions;
